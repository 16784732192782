var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('inventoryList.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('inventoryList.pageTitle'),
                    active: true
                }
            ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-space-x-3"},[_c('a-range-picker',{staticStyle:{"width":"200px"},attrs:{"allowClear":false,"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY"},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),(!_vm.$whois.shop())?_c('a-select',{staticStyle:{"width":"200px"},attrs:{"show-search":"","placeholder":_vm.$t('inventoryList.shopName'),"default-active-first-option":false,"show-arrow":false,"filter-option":false,"not-found-content":null,"loading":_vm.shop_loading},on:{"search":_vm.handleSearch,"change":_vm.onCustomerChange},model:{value:(_vm.shopId),callback:function ($$v) {_vm.shopId=$$v},expression:"shopId"}},_vm._l((_vm.shopList),function(s){return _c('a-select-option',{key:s.shop_id},[_vm._v(" "+_vm._s(s.shop_name)+" ")])}),1):_vm._e(),_c('ts-button',{on:{"click":function($event){$event.preventDefault();return _vm.onPdf.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-file-pdf tw-text-red-500"})])],1),_c('a-tooltip',{attrs:{"placement":"topLeft","title":_vm.$t('inventoryList.search')}},[_c('a-input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('inventoryList.search')),expression:"$t('inventoryList.search')"}],staticClass:"inline-block",staticStyle:{"width":"250px"},attrs:{"placeholder":_vm.$t('inventoryList.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('div',{staticClass:"tw-overflow-x-scroll"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.shop_name))]),_c('td',[_vm._v(_vm._s(record.product_name_kh))]),_c('td',[_vm._v(_vm._s(record.product_name_en))]),_c('td',[_vm._v(_vm._s(record.uom_name_en))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(record.begining_balance)+" ")]),_c('td',{staticClass:"tw-text-center"},[_vm._v(_vm._s(record.stock_in))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(_vm._s(record.stock_out))]),_c('td',{staticClass:"tw-text-center"},[(record.quantity_pending > 0)?_c('button',{staticClass:"tw-bg-blue-500 tw-px-3 tw-rounded tw-text-white",attrs:{"disabled":record.quantity_pending == 0},on:{"click":function($event){$event.preventDefault();return _vm.onShowPending(record)}}},[_vm._v(" "+_vm._s(record.quantity_pending)+" ")]):_c('span',[_vm._v(_vm._s(record.quantity_pending))])]),_c('td',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(record.ending_balance)+" ")])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1),_c('a-modal',{attrs:{"title":_vm.$t('pdf'),"width":"50%","footer":null},model:{value:(_vm.preview_pdf),callback:function ($$v) {_vm.preview_pdf=$$v},expression:"preview_pdf"}},[_c('ts-preview-pdf',{attrs:{"src":_vm.src},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1),_c('a-modal',{attrs:{"title":_vm.$t('inventoryList.pending'),"width":"90%","centered":"","footer":null,"zIndex":1024},model:{value:(_vm.show_pending),callback:function ($$v) {_vm.show_pending=$$v},expression:"show_pending"}},[_c('div',{staticClass:"tw-w-full tw-overflow-x-auto"},[_c('ts-table',{attrs:{"columns":_vm.pendingListcolumns,"records":_vm.pendingResources,"loading":_vm.loading_pending_list},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.booking_time))]),_c('td',[_vm._v(_vm._s(record.booking_number))]),_c('td',[_vm._v(_vm._s(record.packaging_date))]),_c('td',[_vm._v(_vm._s(record.packaging_number))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(_vm._s(record.stock_quantity))]),_c('td',[_vm._v(_vm._s(record.destination))]),_c('td',[_vm._v(_vm._s(record.receiver_phone))]),_c('td',[_vm._v(_vm._s(record.delivery_status))]),_c('td',[_vm._v(_vm._s(record.status_changed_date))]),_c('td',[_vm._v(_vm._s(record.status_remark))])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }